<template>
  <v-container>
    <!-- select date -->
    <v-layout row wrap justify-space-between class="px-2">
      <v-flex xs12 sm6 md4 lg4>
        <h1 class="body-1 font-weight-regular">
          {{ $t("welcome") }},
          <span class="font-weight-bold">{{ userName() }}</span>
        </h1>
      </v-flex>
      <v-flex xs12 sm6 md4 lg4>
        <v-dialog
          persistent
          ref="dialogdate"
          v-model="dialogdate"
          :return-value.sync="selectedDate"
          offset-y
          max-width="300"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-on="on"
              outlined
              dense
              :color="$store.state.secondaryColor"
              prepend-inner-icon="mdi-calendar"
              placeholder="Date"
              readonly
              :value="selectedDate"
            ></v-text-field>
          </template>
          <v-date-picker
            :color="$store.state.primaryColor"
            v-model="selectedDate"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              :color="$store.state.primaryColor"
              @click="dialogdate = false"
              >{{ $t("cancel") }}</v-btn
            >

            <v-btn
              text
              :color="$store.state.secondaryColor"
              @click="
                $refs.dialogdate.save(selectedDate);
                filterSalesByDate();
                filterExpenses();
                getLineData();
                getDailySales();
                getTrends();
                calculateStockValue();
                calculatePurchasesValue();
              "
              >{{ $t("ok") }}</v-btn
            >
          </v-date-picker>
        </v-dialog>
      </v-flex>
    </v-layout>

    <!-- summary analytics -->
    <v-layout class="">
      <v-flex xs12 sm12 md12 lg12>
        <v-layout row wrap class="mb-2">
          <v-flex xs12 sm6 md3 lg3 class="">
            <!-- ################ -->
            <v-col cols="12" md="12">
              <v-card
                class="d-flex"
                height="100"
                elevation="3"
                @click="salesPage()"
                style="cursor: pointer"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span
                        class="primary--text caption font-weight-bold text-uppercase"
                      >
                        {{ $t("sales") }}</span
                      >
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="black--text font-weight-bold pa-1 title"
                      >{{
                        currencyFormat(filteredSalesByDate)
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <!-- ################# -->
          </v-flex>

          <v-flex xs12 sm6 md3 lg3 class="">
            <!-- ################ -->
            <v-col cols="12" md="12">
              <v-card
                class="d-flex"
                height="100"
                elevation="3"
                @click="expensesPage()"
                style="cursor: pointer"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span
                        class="primary--text caption font-weight-bold text-uppercase"
                        >{{ $t("expenses") }}</span
                      >
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="black--text font-weight-bold pa-1 title"
                      >{{
                        currencyFormat(filteredExpensesByDate)
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <!-- ################# -->
          </v-flex>

          <v-flex
            xs12
            sm6
            md3
            lg3
            v-for="item in dashAccessRight"
            :key="item.icon"
            class=""
          >
            <v-col cols="12" md="12">
              <v-card
                class="d-flex"
                height="100"
                elevation="3"
                @click="redirect(item.title, item.route)"
              >
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span
                        class="primary--text caption font-weight-bold text-uppercase"
                        >{{ item.text }}</span
                      >
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="black--text font-weight-bold pa-1 title"
                      v-if="item.currency"
                      >{{ currencyFormat(item.amount) }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      class="black--text font-weight-bold pa-1 title"
                      v-else
                    >
                      {{ item.amount }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <div v-if="graphs">
      <div v-show="loading" class="mt-3">
        <PleaseWait></PleaseWait>
      </div>
      <v-row v-show="!loading">
        <v-col cols="12">
          <v-card
            v-if="lineChartData.datasets[0].data.length"
            class="pa-2"
            elevation="5"
          >
            <v-row justify="space-around">
              <v-col cols="12" md="9">
                <v-card-title>
                  <span class="caption text-uppercase font-weight-bold"
                    >{{ $t("daily sales graph") }}(Week {{ currentWeek }})</span
                  >
                </v-card-title>
                <LineChart
                  :chartdata="lineChartData"
                  :options="lineChartOptions"
                  :style="myStylesLine"
                />
              </v-col>
              <v-col cols="12" md="3" align-self="center">
                <v-list dense class="pa-0">
                  <v-subheader class="caption text-capitalize font-weight-bold"
                    >{{ $t("daily sales") }}(Week
                    {{ currentWeek }})</v-subheader
                  >
                  <v-list-item
                    v-for="(item, i) in weeklySales"
                    :key="i"
                    class="caption px-1"
                  >
                    <v-list-item-icon class="mx-0">
                      <v-icon x-small left :color="item.color"
                        >fas fa-square</v-icon
                      >
                    </v-list-item-icon>
                    <span>{{ item.day }}</span>
                    <v-spacer></v-spacer>
                    <strong>{{ currencyFormat(item.amount) }}</strong>
                  </v-list-item>
                  <v-divider class="my-1"></v-divider>
                  <v-list-item class="subtitle-1 pa-0">
                    <span class="font-weight-black">{{ $t("total") }}: </span>
                    <v-spacer></v-spacer>
                    <strong
                      class="pink lighten-5 error--text text--darken-3 py-1 rounded"
                      >{{ currencyFormat(WeeklyTotalSales) }}</strong
                    >
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card
            v-if="barChartData.datasets[0].data.length"
            class="pa-2"
            elevation="5"
          >
            <v-row>
              <v-col cols="12" md="9">
                <v-card-title class="caption text-uppercase font-weight-bold">{{
                  $t("monthly sales graph")
                }}</v-card-title>
                <BarChart
                  :chartdata="barChartData"
                  :options="barChartOptions"
                  :styles="myStylesBar"
                />
              </v-col>
              <v-col cols="12" md="3" align-self="center">
                <v-card-title>
                  <span
                    class="primary--text body-1 font-weight-bold text-uppercase"
                    >{{ $t("month") }}:</span
                  ><br />
                  <span class="body-1 black--text font-weight-bold pa-1 title">
                    {{ currentMonth }}</span
                  >
                </v-card-title>

                <v-card-subtitle class="mt-2">
                  <span
                    class="primary--text body-1 font-weight-bold text-uppercase"
                    >{{ $t("sales amount") }}</span
                  ><br />
                  <span
                    class="body-1 black--text font-weight-bold pa-1 title"
                    >{{ currencyFormat(currentMonthSales) }}</span
                  >
                </v-card-subtitle>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>
              <span class="caption font-weight-bold text-capitalize">{{
                $t("recent sales")
              }}</span>
            </v-card-title>

            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="filterRecentOrder"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :items-per-page="5"
                :loading="loading"
                loading-text="Loading... Please wait"
              >
                <template v-slot:[`item.qty`]="{ item }">
                  {{ item.products.length }}
                </template>
                <template v-slot:[`item.dateTime`]="{ item }">
                  {{ formatDate(item.dateTime) }}
                </template>

                <template v-slot:[`item.businessId`]="{ item }">
                  {{ getBusinessName(item.businessId) }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card v-if="doughnutChartData.datasets[0].data.length">
            <v-card-title>
              <span class="caption font-weight-bold text text-capitalize"
                >{{ $t("product trends") }} (Top 5)</span
              >
            </v-card-title>
            <DoughnutChart
              :chartdata="doughnutChartData"
              :options="doughnutChartOptions"
            />
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <div v-show="loading" class="mt-3">
        <PleaseWait></PleaseWait>
      </div>
      <h1 v-show="!loading" class="text-center title grey lighten-3">
        {{ $t("dashboard graphs are off") }}
        <span
          @click="dashboardSettings()"
          class="primary--text font-weight-bold text-uppercase"
          style="cursor: pointer"
        >
          {{ $t("click here to turn on") }}
        </span>
      </h1>
    </div>
  </v-container>
</template>

<script>
import jwtdecode from "jwt-decode";
import { format, getMonth, getWeek, getDay } from "date-fns";
import days from "@/data/days";
import months from "@/data/months";
import db from "@/plugins/fb";
import LineChart from "@/components/layout/charts/LineChart.vue";
import BarChart from "@/components/layout/charts/BarChart.vue";
import DoughnutChart from "@/components/layout/charts/DoughnutChart.vue";
import PleaseWait from "@/components/templates/PleaseWait";
export default {
  components: {
    LineChart,
    BarChart,
    PleaseWait,
    DoughnutChart,
  },
  data: () => ({
    graphs: "",
    PayrollTemplateDate: "",
    sortBy: "dateTime",
    sortDesc: true,

    dialogdate: false,
    stockValue: "",

    loading: true,
    selectedDate: format(new Date(), "yyyy-MM-dd"),
    WeeklyTotalSales: 0,
    user: "",
    sales: [],
    receipts: [],

    allStock: [],
    allExpenses: [],
    selectedShop: "",
    currentWeek: "",
    filteredSalesByDate: "",
    filteredExpensesByDate: "",
    days,
    months,

    //charts data
    gradientLine: "",
    myStylesLine: {
      height: "250px",
    },

    myStylesBar: {
      height: "250px",
    },

    purchases: [],
    purchasesValue: "",
  }),
  computed: {
    dashlist() {
      return [
        {
          icon: "mdi-account-group",
          title: "Stock value",
          text: this.$t("stock value"),
          color: "pink ",
          amount: 0,
          currency: false,
          level: [0, 1, 2],
          route: "StockItems",
        },
        {
          icon: "mdi-basket",
          title: "Purchases",
          text: this.$t("purchases"),
          color: "light-blue",
          amount: 0,
          currency: false,
          level: [99],
          route: "ViewPurchaseOrders",
        },
      ];
    },
    lineChartData() {
      return {
        labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        datasets: [
          {
            label: this.$t("weekly sales trend"),
            backgroundColor: "#05cbe196",
            data: [],
          },
        ],
      };
    },
    lineChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: true,
              },
            },
          ],
        },
      };
    },
    weeklySales() {
      return [
        { day: "Sun", amount: 0, color: "blue darken-2" },
        { day: "Mon", amount: 0, color: "green darken-1" },
        { day: "Tue", amount: 0, color: "pink darken-3" },
        { day: "Wed", amount: 0, color: "indigo darken-2" },
        { day: "Thu", amount: 0, color: "cyan accent-3" },
        { day: "Fri", amount: 0, color: "orange darken-4" },
        { day: "Sat", amount: 0, color: "yellow darken-4" },
      ];
    },
    barChartData() {
      return {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: this.$t("monthly sales"),
            backgroundColor: "#05cbe196",

            data: [],
          },
        ],
      };
    },
    barChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: true,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      };
    },
    doughnutChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
        },
      };
    },
    doughnutChartData() {
      return {
        labels: [],
        datasets: [
          {
            label: this.$t("product trends"),
            backgroundColor: ["#05cbe1", "#ee8837", "#5c08ac", "#127d9e"],
            data: [],
          },
        ],
      };
    },
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },
    dashAccessRight() {
      let accessRight = this.user.accessLevel.isSuperAdmin
        ? 0
        : this.user.accessLevel.isOwner
        ? 1
        : this.user.accessLevel.isManager
        ? 2
        : 3;
      return this.dashlist.filter((val) => {
        if (val.level.includes(99)) return val;
        else if (val.level.includes(accessRight)) return val;
      });
    },
    salesAmount() {
      let allReceipts = this.sales;
      let receiptsPerDate = allReceipts.filter(
        (item) => this.formatDate(item.dateTime) == this.selectedDate
      );
      let sumAmounts = 0;
      receiptsPerDate.forEach((val) => {
        sumAmounts += Number(val.amount);
      });
      return sumAmounts.toFixed(2);
    },
    currentMonth() {
      return format(new Date(this.selectedDate), "MMMM");
    },
    //calculate total sales for the current month
    currentMonthSales() {
      let monthIndex = getMonth(new Date(this.selectedDate));
      let allReceipts = this.receipts;
      let receiptsOfMonth = allReceipts.filter(
        (item) => getMonth(item.dateTime.toDate()) == monthIndex
      );

      let amounts = receiptsOfMonth.map((item) => Number(item.totalAmount));

      let sumAmounts =
        amounts.length > 1
          ? amounts.reduce((prev, next) => prev + next)
          : amounts[0] != null
          ? amounts[0]
          : 0;

      return sumAmounts.toFixed(2);
    },
    currentDay() {
      let dayIndex = getDay(new Date());
      return this.days[dayIndex];
    },
    filterRecentOrder() {
      // #########
      let allReceipts = this.receipts;
      let receiptsOfDay = allReceipts.filter(
        (item) => this.formatDate(item.dateTime) == this.selectedDate
      );
      return receiptsOfDay;
    },
    headers() {
      return [
        {
          text: this.$t("receipt number"),
          value: "receiptNo",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("total amount"),
          value: "totalAmount",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("quantity"),
          value: "qty",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("payment method"),
          value: "paymentMethod",
          class: "blue-grey darken-3 white--text font-weight-regular",
          align: "center",
        },
        {
          text: this.$t("transaction date"),
          value: "dateTime",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("business"),
          value: "businessId",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
      ];
    },
  },
  created() {
    this.getUserType();
    this.getSettings();
    this.getUser();
    this.getStockCount();
    this.getSales();
    this.expenses();
    this.getPurchases();
    this.getReceipts();
    this.getShifts();
    // this.generatePayrollTemplate();
  },
  methods: {
    //determine where user belongs
    getUserType() {
      //Fetching current user details
      var data = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );
      var userAccess = data.accessLevel.isSuperAdmin
        ? 0
        : data.accessLevel.isOwner
        ? 1
        : data.accessLevel.isManager
        ? 2
        : data.accessLevel.isWaiter
        ? 3
        : data.accessLevel.isChef
        ? 4
        : data.accessLevel.isCashier
        ? 5
        : 6;
      //redirect
      if (userAccess == 2) {
        this.$store.commit("SET_WINDOWTITLE", "Manager");
      }

      if (userAccess == 3) {
        this.$store.commit("SET_WINDOWTITLE", "Waiter");
        this.$router.push({ name: "RestaurantPOS" });
      }
      if (userAccess == 4) {
        this.$store.commit("SET_WINDOWTITLE", "Chef");
        this.$router.push({ name: "Orders" });
      }
      if (userAccess == 5) {
        this.$store.commit("SET_WINDOWTITLE", "Cashier");
        this.$router.push({ name: "Orders" });
      }
    },
    getSettings() {
      if (this.$store.state.employerId) {
        db.collection("configurations")
          .doc(this.$store.state.employerId)
          .get()
          .then((snaps) => {
            if (snaps.exists) {
              this.graphs = snaps.data().dashboardGraphs;
              this.PayrollTemplateDate = snaps.data().dateOfPayrollTemplate;
            }
            // this.generatePayrollTemplate();
          })
          .catch(() => {
            this.snackMessage = true;
          });
      } else {
        //Fetching current user details
        var data = jwtdecode(
          this.$store.state.token,
          this.$store.state.accessKey
        );
        db.collection("configurations")
          .doc(data.accessLevel.username)
          .get()
          .then((snaps) => {
            if (snaps.exists) {
              this.graphs = snaps.data().dashboardGraphs;
              this.PayrollTemplateDate = snaps.data().dateOfPayrollTemplate;
            }

            // this.generatePayrollTemplate();
          })
          .catch(() => {
            this.snackMessage = true;
          });
      }
      // ######
    },

    getShifts() {
      this.shifts = [];
      if (this.selected) {
        db.collection("shifts")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.shifts.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            this.$store.commit("SET_SHIFTS", this.shifts);
          });
      } else {
        db.collection("shifts")
          .where("businessId", "in", this.$store.getters.getBusinessId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.shifts.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.$store.commit("SET_SHIFTS", this.shifts);
          });
      }
    },
    expenses() {
      if (this.selected) {
        db.collection("expenses")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.allExpenses.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.filterExpenses();
          });
      } else {
        db.collection("expenses")
          .where("businessId", "in", this.$store.getters.getBusinessId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.allExpenses.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            this.filterExpenses();
          });
      }
    },
    getPurchases() {
      if (this.selected) {
        db.collection("purchaseOrders")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.purchases.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.calculatePurchasesValue();
          });
      } else {
        db.collection("purchaseOrders")
          .where("businessId", "in", this.$store.getters.getBusinessId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.purchases.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.calculatePurchasesValue();
          });
      }
    },
    calculatePurchasesValue() {
      // #########get value of purchases before and including selected date##########
      let allPurchases = this.purchases;
      let purchasesOfDay = allPurchases.filter(
        (item) => this.formatDate(item.dateTime) <= this.selectedDate
      );

      let amounts = purchasesOfDay.map((item) => Number(item.total));
      let sumAmounts =
        amounts.length > 1
          ? amounts.reduce((prev, next) => prev + next)
          : amounts[0] != null
          ? amounts[0]
          : 0;

      this.dashlist[1].amount = this.currencyFormat(sumAmounts);
    },
    getUser() {
      this.user = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );
    },
    userName() {
      return `${this.user.names.first} ${this.user.names.middle.substr(
        0,
        1
      )}. ${this.user.names.last}`;
    },
    currencyFormat(val) {
      var decimalCount = 2;
      var decimal = ".";
      var thousands = ",";
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
      const negativeSign = val < 0 ? "-" : "";
      let i = parseInt(
        (val = Math.abs(Number(val) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;
      return (
        this.$store.state.currency.symbol +
        "  " +
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(val - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    },

    getStockCount() {
      if (this.selected) {
        db.collection("products")
          .where("businessID", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((snaps) => {
              this.allStock.push({
                id: snaps.id,
                ...snaps.data(),
              });
            });
            this.calculateStockValue();
          });
      } else {
        db.collection("products")
          .where("businessID", "in", this.$store.getters.getBusinessId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.allStock.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.calculateStockValue();
          });
      }
    },
    calculateStockValue() {
      // #########get value of stock before and including selected date##########
      let allStock = this.allStock;
      let stockOfDay = allStock.filter(
        (item) => this.formatDate(item.lastUpdateDate) <= this.selectedDate
      );

      let amounts = stockOfDay.map((item) => Number(item.price.selling));

      let quantity = stockOfDay.map((item) => Number(item.quantity.inStock));
      let sumAmounts = 0;
      for (let i = 0; i < stockOfDay.length; i++) {
        //multiply amount with qunatity in stock
        sumAmounts += amounts[i] * quantity[i];
      }

      this.dashlist[0].amount = this.currencyFormat(sumAmounts);
    },
    formatDate(val) {
      var date = val.toDate();
      return format(date, "yyyy-MM-dd");
    },
    getBusinessName(id) {
      let bizId = this.$store.getters.getBusinesses.find(
        (item) => item.id == id
      );
      return bizId.businessName;
    },
    //get receipts for table
    getReceipts() {
      if (this.selected) {
        db.collection("receipts")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.receipts.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            // this.currentDaySales();
            this.filterSalesByDate();
            this.getLineData();
            this.getDailySales();
            this.getBarData();
          });
      } else {
        db.collection("receipts")
          .where("businessId", "in", this.$store.getters.getBusinessId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.receipts.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            // this.currentDaySales();
            this.filterSalesByDate();
            this.getLineData();
            this.getDailySales();
            this.getBarData();
          });
      }
    },
    //get sales
    getSales() {
      if (this.selected) {
        db.collection("transactions")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.sales.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            this.loading = false;
            this.getTrends();
          });
      } else {
        db.collection("transactions")
          .where("businessId", "in", this.$store.getters.getBusinessId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.sales.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            this.loading = false;
            this.getTrends();
          });
      }
    },

    filterSalesByDate() {
      // #########
      let allReceipts = this.receipts;
      let receiptsOfDay = allReceipts.filter(
        (item) => this.formatDate(item.dateTime) == this.selectedDate
      );
      let amounts = receiptsOfDay.map((item) => Number(item.totalAmount));
      let sumAmounts =
        amounts.length > 1
          ? amounts.reduce((prev, next) => prev + next)
          : amounts[0] != null
          ? amounts[0]
          : 0;
      //##############
      this.filteredSalesByDate = sumAmounts;
    },
    filterExpenses() {
      let allExpenses = this.allExpenses;
      let expensesOfDay = allExpenses.filter(
        (item) => this.formatDate(item.dateTime) == this.selectedDate
      );
      let amounts = expensesOfDay.map((item) => Number(item.amount));
      let sumAmounts =
        amounts.length > 1
          ? amounts.reduce((prev, next) => prev + next)
          : amounts[0] != null
          ? amounts[0]
          : 0;
      this.filteredExpensesByDate = sumAmounts.toFixed(2);
    },
    getLineData() {
      let lineData = [];
      //account for range error
      this.currentWeek = getWeek(new Date(this.selectedDate), {
        weekStartsOn: 1,
        firstWeekContainsDate: 4,
      });
      // get current week transactions
      let currentWeekReceipts = this.receipts.filter(
        (item) =>
          getWeek(item.dateTime.toDate(), {
            weekStartsOn: 1,
            firstWeekContainsDate: 4,
          }) == this.currentWeek
      );

      for (let i = 0; i < this.days.length; i++) {
        let weekReceipts = currentWeekReceipts.filter(
          (item) => getDay(item.dateTime.toDate()) == i
        );
        if (weekReceipts.length) {
          let weekSales = weekReceipts.map((item) => Number(item.totalAmount));

          let weekReceiptsSum =
            weekSales.length > 1
              ? weekSales.reduce((prev, next) => prev + next)
              : weekSales[0] != null
              ? weekSales[0]
              : 0;

          lineData.push(weekReceiptsSum);
          //after done with one day repopulate perDaySalesSum to 0
          weekReceiptsSum = 0;
        } else {
          lineData.push(0);
        }
      }
      this.lineChartData.datasets[0].data = lineData;
    },
    getDailySales() {
      //empty out WeeklyTotalSales summation
      this.WeeklyTotalSales = 0;
      let currentWeek = getWeek(new Date(this.selectedDate), {
        weekStartsOn: 1,
        firstWeekContainsDate: 4,
      });
      let currentWeekReceipts = this.receipts.filter(
        (item) =>
          getWeek(item.dateTime.toDate(), {
            weekStartsOn: 1,
            firstWeekContainsDate: 4,
          }) == currentWeek
      );

      for (let i = 0; i < this.days.length; i++) {
        let weekReceipts = currentWeekReceipts.filter(
          (item) => getDay(item.dateTime.toDate()) == i
        );
        if (weekReceipts.length) {
          let weekSales = weekReceipts.map((item) => Number(item.totalAmount));
          let weekReceiptsSum =
            weekSales.length > 1
              ? weekSales.reduce((prev, next) => prev + next)
              : weekSales[0];
          this.weeklySales[i].amount = weekReceiptsSum;
          this.WeeklyTotalSales += weekReceiptsSum;
        } else {
          this.weeklySales[i].amount = 0;
        }
      }
    },
    //barchart for monthly sales
    getBarData() {
      let barData = [];
      for (let i = 0; i < this.months.length; i++) {
        let monthTransactions = this.receipts.filter(
          (item) => getMonth(item.dateTime.toDate()) == i
        );
        if (monthTransactions.length) {
          let monthSales = monthTransactions.map((item) =>
            Number(item.totalAmount)
          );
          let monthSalesSum =
            monthSales.length > 1
              ? monthSales.reduce((prev, next) => prev + next)
              : monthSales[0];
          barData.push(monthSalesSum);
        } else {
          barData.push(0);
        }
      }
      this.barChartData.datasets[0].data = barData;
    },
    //doughnut chart for top five most sold items
    getTrends() {
      let Trends = [];
      let SortedTrends = [];
      let Labels = [];
      let SortedLabels = [];
      let i = 0;
      let j = 0;
      const size = 5;
      let pIds = [];
      let index = "";
      this.doughnutChartData.labels = [];
      this.doughnutChartData.datasets[0].data = [];
      this.doughnutChartData.datasets[0].backgroundColor = [
        "#2196F3",
        "#BBDEFB",
        "#64B5F6",
        "#1E88E5",
        "#0D47A1",
        "0091EA",
      ];
      for (i; i < this.sales.length; i++) {
        if (pIds.includes(this.sales[i].productId)) {
          //get index of the product
          index = pIds.findIndex((item) => item === this.sales[i].productId);
          //add an increament of one for each time we find the item
          Trends[index] += this.sales[i].quantity;
          SortedTrends[index] += this.sales[i].quantity;
        } else {
          pIds.push(this.sales[i].productId);
          //get index of the product
          index = pIds.findIndex((item) => item === this.sales[i].productId);
          //initiate the first entry for the first time we meet the product
          Trends[index] = this.sales[i].quantity;
          SortedTrends[index] = this.sales[i].quantity;
          //push the data labels
          Labels.push(this.sales[i].name);
        }
      }
      let highestToLowest = SortedTrends.sort((a, b) => b - a);
      SortedTrends = highestToLowest;
      for (j; j < highestToLowest.length; j++) {
        let x = highestToLowest[j];
        index = Trends.findIndex((item) => item === x);
        SortedLabels.push(Labels[index] + " (" + Trends[index] + ")");
      }
      //slice to pick the top 5 selling items
      this.doughnutChartData.datasets[0].data = SortedTrends.slice(0, size);
      this.doughnutChartData.labels = SortedLabels.slice(0, size);
    },
    //load expenses page
    expensesPage() {
      this.$router.push({ name: "ChartAccount" });
    },

    salesPage() {
      this.$router.push({ name: "ViewSales" });
    },
    //update window tile
    redirect(title, route) {
      this.$store.commit("SET_WINDOWTITLE", "");
      this.$store.commit("SET_WINDOWTITLE", title);
      this.$router.push({ name: route });
    },
    //redirect to settings.
    dashboardSettings() {
      this.$router.push({ name: "SystemGeneral" });
    },
    //generate payroll template
    // generatePayrollTemplate() {
    //   if (this.PayrollTemplateDate) {
    //     if (getDate(new Date()) >= this.PayrollTemplateDate) {
    //       console.log("add payroll");
    //     } else {
    //       console.log("false");
    //     }
    //   }
    // },
  },
};
</script>

<style scoped>
.input-form {
  padding: 5px;
  background: #fff;
  text-align: right;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.title {
  background-color: aliceblue;
}
.summaryCard {
  margin-top: -15px;
  margin-left: -20px;
  margin-bottom: 30px;
  padding: 10px;
  border-radius: 5%;
  background-color: #5c08ac;
  transition: transform 0.2s;
}
.summaryCard:hover {
  /* padding:50px; */
  transform: scale(1.2);
}
</style>
